<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
  <CSidebarBrand>
      <div class="col-md-12" style="align-content: center;text-align: center;">
        <img class="sidebar-brand-narrow mx-2" :src="COMPANY_DATA.logo_small_path" height="45" :alt="COMPANY_DATA.company_name">
        <!-- <div class="" style="width:70%;">
          <img  class="sidebar-brand-full mb-2 mt-2" :src="COMPANY_DATA.logo_path" height="55" :alt="COMPANY_DATA.company_name">
        </div> -->
        <img style="width:100%;" class="sidebar-brand-full mb-2 mt-2" :src="COMPANY_DATA.logo_path" height="55" :alt="COMPANY_DATA.company_name">
      </div>
    </CSidebarBrand>
    <AppSidebarNav />
    <!-- <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    /> -->
    <CSidebarToggler class="d-none d-lg-flex bg-primary" style="position: relative;" @click="$store.commit('toggleUnfoldable')">
      <div class="title-dashboard" >
        Panel Admin  
      </div>
    </CSidebarToggler>
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import { COMPANY_DATA } from '@/config';
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.coreUI.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.coreUI.sidebarVisible),
      COMPANY_DATA
    }
  },
}
</script>
<style>
.title-dashboard {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  /* font-weight: bold; */
  font-size: 1rem;
  padding: 0.8rem; 
}

/* responsive class title-dashboard */
.sidebar-fixed.sidebar-narrow-unfoldable:not(:hover) .title-dashboard {
  display: none;
}
</style>