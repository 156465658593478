
import { httpClient, setResponseType } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

// domain/api/admin
const BASE_API = apiGlobalConstants.admin;

export const downloadExcel = (filename, model = null ) => {
  setResponseType('blob');
  return httpClient.post(`${model}/to-excel`, {name: filename}).then(res => {
    setResponseType('json');
    return res
  });
};

export const resetResponseType = () => {
  setResponseType('json');
};

// api/admin/medicos/suggest?nombre=
// api/admin/centros/suggest?nombre=
// api/admin/pacientes/suggest?nombre=
export const suggestGlobal = (model, term = "") => {
  let filter = `nombre=${term}`;
  return httpClient.get(`${BASE_API}/${model}/suggest?${filter}`).then(res => res.data);
};





