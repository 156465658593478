<template>
  <CFooter>
    <div>
      <a href="https://balderma.com/" target="_blank">{{ COMPANY_DATA.company_name }}</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}</span>
      <div>
          <a @click.prevent="openFichero(COMPANY_DATA.consentimiento_explicito)" href="#">
            Consentimiento
          </a>
     
          <a @click.prevent="openFichero(COMPANY_DATA.derecho_desistimiento)" href="#">
            Formulario de solicitud
          </a>
     
          <a @click.prevent="openFichero(COMPANY_DATA.condicion_general_contratacion)" href="#">
            Condiciones generales contratacion
          </a>
            
          <a @click.prevent="openFichero(COMPANY_DATA.ficha_diagnostico)" href="#">
            Ficha diagnóstico
          </a>
        
      </div>
    </div>
    <div class="m-auto">
      <a :href="COMPANY_DATA.terminos_condiciones" class="px-2" target="_blank">Términos y condiciones</a> |
      <a :href="COMPANY_DATA.aviso_legal" class="px-2" target="_blank">Avisos legales</a> 
      | <a :href="COMPANY_DATA.cookies" class="px-2" target="_blank">Políticas de cookies</a>
    </div>
    <!-- <div class="">
      <span class="me-1">Desarollado por</span>
      <a href="https://www.trestristestigres.com" target="_blank"><b>tres</b>tristes<b>tigres</b></a>
    </div> -->
  </CFooter>
</template>

<script>
import { COMPANY_DATA } from '@/config';
import { downloadDocumentos } from '@/app/shared/global/services/fichero-service';

export default {
  name: 'AppFooter',
  setup() {
    return {
      COMPANY_DATA
    }
  },
  methods: {
    async openFichero(filename) {
      try {
        const response = await downloadDocumentos(filename);

        if (response.status == 'error') {
          throw new Error('No se pudo descargar el archivo');
        }

        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

        // opción abrir ventana, no se modifica el nombre y es un slug
        // window.open(url, '_blank');

        // Crear un enlace temporal para la descarga
        const link = document.createElement('a');
        link.href = url;
        link.download = filename; // Establece el nombre del archivo para la descarga
        document.body.appendChild(link);
        link.click();

        // Limpiar el enlace y la URL del Blob
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } catch (error) {
        console.error("Error al descargar el PDF:", error);
        this.$refs.toast.showToast('Error al abrir el PDF. Por favor, inténtalo de nuevo.' ,null, 'error', '5000');
      }
    },
  }

}
</script>
