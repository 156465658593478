<template>
  <div class="animated fadeIn">
    <CModal 
    v-model:visible="showModal"
    size="lg"
    @close="() => $emit('cancelled',false)"
    alignment="center"
    >

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>Nueva Lista de MKT</CModalTitle>
      </CModalHeader>

      <CModalBody class="d-block">

        <p class="ml-2" v-if="!query && !query.length" style="color: var(--orange-wheel)">
          No hay ningún filtro activo. Seleccionados todos los resultados
        </p>


        <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
          <CCol sm="9" class="mt-3">
              <CFormLabel class="required">Nombre</CFormLabel>
              <Field
                as="CFormInput"
                type="text"
                id="name" name="nombre"
                :rules="'required'"
                placeholder="Introduce un nombre"
                v-model="nombre"
                :class="{ 'is-invalid': formError(errors, 'nombre')}"
                v-bind:invalid-feedback="errors.name"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'nombre')">{{ errors.nombre }}</small>
            </CCol>

            <!-- <pre>{{ query }}</pre> -->
        </Form>
      </CModalBody>

       <CModalFooter>
        <CButton @click="$emit('cancelled',false)" class="text-secondary" color="link">Cancelar</CButton>
        <CButton class="text-light" @click="guardar" color="primary">
          Crear
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
</template>

<script>
import { formError } from '@/app/shared/utils/tools';

export default {
  name: 'CreaListaMKTModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    query: { type: String, required: false },

  },
  data() {
    return {
      nombre: ''
    }
  },
  mounted() {

  },
  computed: {
    showModal() {
      return this.show;
    },
  },
  methods: {
    formError(errors, value) {
      return formError(errors, value)
    },

    guardar() {
      this.$refs.form.validate().then(async v => {
        if (!v.valid) return

        this.$emit('guardarListaMKT', this.nombre);
        this.nombre = ''
      });
    },


  }
}
</script>
